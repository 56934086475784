<template>
  <div class="wizard-body height-auto small-9 columns">
    <page-header
      :header-title="$t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.TITLE')"
      :header-content="$t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.DESC')"
    />
    <woot-loading-state
      v-if="uiFlags.isCreating"
      :message="$t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.LOADING_MESSAGE')"
    />
    <form
      v-if="!uiFlags.isCreating"
      class="row"
      @submit.prevent="createChannel"
    >
      <div class="medium-12 columns">
        <label>
          {{ $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.LABEL') }}
          <input
            v-model.trim="inboxName"
            type="text"
            :placeholder="$t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.PLACEHOLDER')"
          />
        </label>
      </div>


      <div class="medium-12 columns">
        <label>
          {{ $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_ID.LABEL') }}
          <input
            v-model.trim="channelGoogleBusinessId"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_ID.PLACEHOLDER')
            "
          />
        </label>
      </div>


      <div class="medium-12 columns">
        <label>
          {{ $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_WEBHOOK_VERIFY_TOKEN.LABEL') }}
          <input
            v-model.trim="channelGoogleBusinessWebhookVerifyToken"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_WEBHOOK_VERIFY_TOKEN.PLACEHOLDER')
            "
          />
        </label>
      </div>


      <div class="medium-12 columns">
        <label>
          {{ $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_AGENT_ID.LABEL') }}
          <input
            v-model.trim="channelGoogleBusinessAgentId"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_AGENT_ID.PLACEHOLDER')
            "
          />
        </label>
      </div>

      
      <div class="medium-12 columns">
        <label>
          {{ $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_AGENT_NAME.LABEL') }}
          <input
            v-model.trim="channelGoogleBusinessAgentName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_AGENT_NAME.PLACEHOLDER')
            "
          />
        </label>
      </div>        
      

      <div class="medium-12 columns">
        <label>
          {{ $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_BRAND_ID.LABEL') }}
          <input
            v-model.trim="channelGoogleBusinessBrandId"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_BRAND_ID.PLACEHOLDER')
            "
          />
        </label>
      </div>


      <div class="medium-12 columns">
        <label>
          {{ $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_BRAND_NAME.LABEL') }}
          <input
            v-model.trim="channelGoogleBusinessBrandName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_BRAND_NAME.PLACEHOLDER')
            "
          />
        </label>
      </div>
      

      <div class="medium-12 columns">

        <label>
          {{ $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_AGENT_DISPLAY_NAME.LABEL') }}
          <input
            v-model.trim="channelGoogleBusinessDisplayName"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_AGENT_DISPLAY_NAME.PLACEHOLDER')
            "
          />
        </label>
      </div>
      
      
      <div class="medium-12 columns">
        <label>
          {{ $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_SERVICE_ACCOUNT_SETTING.LABEL') }}
          <textarea
            v-model.trim="channelGoogleBusinessServiceAccountSetting"
            rows="6"
            type="text"
            :placeholder="
              $t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.GOOGLE_BUSINESS_SERVICE_ACCOUNT_SETTING.PLACEHOLDER')
            "            
          />
        </label>
      </div>          
      
            
      <div class="modal-footer">
        <div class="medium-12 columns">
          <woot-submit-button
            :loading="uiFlags.isCreating"
            :disabled="!channelGoogleBusinessId || !inboxName || !channelGoogleBusinessWebhookVerifyToken || !channelGoogleBusinessAgentId || !channelGoogleBusinessDisplayName || !channelGoogleBusinessBrandId || !channelGoogleBusinessServiceAccountSetting"
            :button-text="$t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.SUBMIT_BUTTON')"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '../../../../index';
import PageHeader from '../../SettingsSubPageHeader';
import GreetingsEditor from 'shared/components/GreetingsEditor';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    PageHeader,
    GreetingsEditor,
  },
  mixins: [alertMixin],
  data() {
    return {
      inboxName: '',      
      channelGoogleBusinessId: '',
      channelGoogleBusinessWebhookVerifyToken: '',
      channelGoogleBusinessAgentId: '',
      channelGoogleBusinessAgentName: '',
      channelGoogleBusinessDisplayName: '',
      channelGoogleBusinessBrandId: '',
      channelGoogleBusinessBrandName: '',      
      channelGoogleBusinessServiceAccountSetting: ''
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
    }),
    textAreaChannels() {
      if (
        this.isATwilioChannel ||
        this.isATwitterInbox ||
        this.isAFacebookInbox
      )
        return true;
      return false;
    },
  },
  methods: {
    async createChannel() {
      try {
        const google_business = await this.$store.dispatch(
          'inboxes/createGoogleBusinessChannel',
          {
            name: this.inboxName,            
            channel: {
              type: 'google_business',              
              google_business_id: this.channelGoogleBusinessId,
              webhook_verify_token: this.channelGoogleBusinessWebhookVerifyToken,
              agent_config: {google_business_agent_id: this.channelGoogleBusinessAgentId,
              agent_name: this.channelGoogleBusinessAgentName,
              display_name: this.channelGoogleBusinessDisplayName,
              brand_id: this.channelGoogleBusinessBrandId,
              brand_name: this.channelGoogleBusinessBrandName,
              service_account_file: '---',
              service_account_setting: this.channelGoogleBusinessServiceAccountSetting,
              current_token: '',
              current_token_expires_at: ''}              
            },
          }
        );
        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: google_business.id,
          },
        });
      } catch (error) {
        this.showAlert(
          error.message ||
            this.$t('INBOX_MGMT.ADD.GOOGLE_BUSINESS.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>
